import React from 'react';
import logoXl from '../../../../assets/images/logo-xl.svg';

export function Splash(): JSX.Element {
  return (
    <div className="splash d-flex justify-content-center align-items-center">
      <img src={logoXl} alt="" />
    </div>
  );
}

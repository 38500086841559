import React from 'react';
import csvIcon from '../../../../../../assets/icons/csv-icon.svg';
import sendIcon from '../../../../../../assets/icons/send-icon.svg';

export function Chat(): JSX.Element {
  return (
    <div className="chat">
      <div className="chat-header d-flex align-items-center">
        <img src={csvIcon} alt="" />
        <h1>CSV File Three , CSV File Seven</h1>
      </div>
      <div className="chat-msgs-container">
        <div className="msg">
          <p>
            It is a long established fact that a reader will be distracted by the readable content of a page
            when looking at its layout. The point of using Lorem Ipsum is
          </p>
        </div>
      </div>
      <div className="new-msg">
        <div className="combine-input form-control d-flex justify-content-between">
          <input
            type="text"
            name="new_msg"
            className="form-control"
            id="new_msg"
            placeholder="Type Message..."
          />
          <button className="btn btn-primary" type="button">
            <img src={sendIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

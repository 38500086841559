import React from 'react';
import { Dropdown } from 'react-bootstrap';
import avatar from '../../../../../assets/icons/avatar.svg';
import dropdownIcon from '../../../../../assets/icons/dropdown-icon.svg';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts';

export function UserDropdown(): JSX.Element {
  const authContext = useAuthContext();

  const handleLogout = () => {
    authContext.updateUser(null);
  };
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          className="dropdown-btn"
          variant="success"
          id="dropdown-basic">
          <div className="user d-flex">
            <div className="user-img">
              <img src={avatar} alt="" className="profile" />
            </div>
            <div className="user-name d-flex flex-column justify-content-center">
              <h1>Expert User</h1>
              <p className="d-flex align-items-center">
                Settings <img src={dropdownIcon} alt="" />
              </p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="user-dropdown-menu">
          <Link className="dropdown-item user-dropdown-item pt-2" to="/">
            Edit Profile
          </Link>
          <Dropdown.Item
            className="dropdown-item user-dropdown-item pb-2"
            onClick={handleLogout}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

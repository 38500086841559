import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Login } from './Authentication';

export function DesktopRouter(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Login} path="/login" />
        <Redirect to="/login" />
      </Switch>
    </BrowserRouter>
  );
}

import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';

export function ValidateCallback(
  callback: (value: any, object: any) => boolean,
  validationOptions?: ValidationOptions
): any {
  return (object: any, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [callback],
      validator: CallbackConstraint
    });
  };
}

@ValidatorConstraint({ name: 'ValidateCallback' })
class CallbackConstraint implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments): boolean {
    const [callback] = args.constraints;
    return callback(value, args.object);
  }
}

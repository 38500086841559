import React from 'react';
import locationIcon from '../../../../../assets/icons/location-icon.svg';
import phoneIcon from '../../../../../assets/icons/phone-icon.svg';
import messageIcon from '../../../../../assets/icons/message-icon.svg';
import { Dropdown } from 'react-bootstrap';

export function Cards(): JSX.Element {
  return (
    <div className="list-cards">
      <div className="list-card">
        <h1>Client name.</h1>
        <h1 className="pt-1 pb-2">
          <span>Last Change Date: </span>30/04/2021
        </h1>
        <div className="d-flex location pt-1">
          <img src={locationIcon} alt="" />
          <h1>H #85 Street 6 Nawab Shah Colony Fts</h1>
        </div>
        <div className="d-flex align-items-center">
          <p>Pending Payments</p>
          <button className="btn btn-danger badge-custom" type="button">
            4500
          </button>
        </div>
        <div className="btns d-flex">
          <button className="btn btn-primary" type="button">
            Assign to
          </button>
          <Dropdown>
            <Dropdown.Toggle
              className="dropdown-btn"
              variant="success"
              id="dropdown-basic">
              <button className="btn msg-icon" type="button">
                <img src={messageIcon} alt="" />
              </button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="msg-dropdown">
              <Dropdown.Item className="dropdown-item msg-dropdown-item">
                <form>
                  <div className="mb-1 col">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      placeholder="Type here..."
                    />
                  </div>
                  <div>
                    <button className="btn btn-primary" type="button">
                      Send
                    </button>
                  </div>
                </form>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button className="btn btn-success" type="button">
            <img src={phoneIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

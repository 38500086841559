import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  StatisticsFilterInterface,
  StatisticsResponseInterface
} from '../../interfaces';
import { API } from '../../api';

export const statisticsThunk = createAsyncThunk<
  StatisticsResponseInterface,
  StatisticsFilterInterface
>('statistics-thunk', async (timestamp, thunkApi) => {
  try {
    const response = await API.Auth.statistics(timestamp);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

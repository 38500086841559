import { IsEmail, IsNotEmpty, IsString } from 'class-validator';
import { Match } from '../shared/custom-validators';
import { UserRole } from '../shared/interfaces';
import { AddressValidator } from './Address.validator';

export class ExpertsValidator {
  @IsString({ message: 'Name must be string' })
  @IsNotEmpty({ message: 'Name should not be empty' })
  firstName: string;

  @IsString({ message: 'Name must be string' })
  @IsNotEmpty({ message: 'Name should not be empty' })
  lastName: string;

  @IsEmail()
  @IsNotEmpty({ message: 'Email should not be empty' })
  email: string;

  @IsString({ message: 'Phone Number must be string' })
  @IsNotEmpty({ message: 'Phone Number should not be empty' })
  phoneNumber: string;

  @IsNotEmpty({ message: 'Password should not be empty' })
  password: string;

  @IsNotEmpty({ message: 'Confirm Password should not be empty' })
  @Match('password', {
    message: 'Password and Confirm Password Must Be The Same'
  })
  confirm_password: string;

  @IsNotEmpty({ message: 'Address should not be empty' })
  address: AddressValidator;

  role: UserRole;
}

import { Address, UserRole } from '../shared/interfaces';
import { AddressValidator } from './Address.validator';
import {
  IsMongoId,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested
} from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { ValidateCallback } from '../shared/custom-validators';

export class NewBuyerValidator {
  @IsString({ message: 'First Name must be string' })
  @IsNotEmpty({ message: 'First Name should not be empty' })
  firstName: string;

  @Expose()
  @IsString({ message: 'Last Name must be string' })
  @IsNotEmpty({ message: 'Last Name should not be empty' })
  lastName: string;

  @Expose()
  @IsString({ message: 'Phone Number must be string' })
  @IsNotEmpty({ message: 'Phone Number should not be empty' })
  phoneNumber: string;

  @Expose()
  @ValidateNested()
  @Type(() => AddressValidator)
  address: AddressValidator;

  @Expose()
  role: UserRole;

  @Expose()
  @IsNotEmpty({ message: 'Payment should not be empty' })
  @IsNumber()
  @ValidateCallback(
    (value, object) => {
      console.log(object);
      return value <= object.payable;
    },
    {
      message: 'Payment cannot be grater then payable'
    }
  )
  payment: number;

  @Expose()
  @IsString({ message: 'Product must be string' })
  @IsNotEmpty({ message: 'Product should not be empty' })
  product: string;

  @Expose()
  @IsNotEmpty({ message: 'Installation Date should not be empty' })
  installationDate: string;

  @Expose()
  @IsNotEmpty({ message: 'Next Date should not be empty' })
  nextServiceDate: string;

  @Expose()
  @IsNumber()
  @IsOptional()
  @ValidateCallback(
    (value, object) => {
      console.log(object);
      return value < object.productPrice;
    },
    {
      message: 'Special Discount cannot be grater then Product Price'
    }
  )
  specialDiscount: number;

  @Expose()
  @IsString()
  @IsOptional()
  sn?: string;

  @Expose()
  @IsNumber()
  @IsOptional()
  payable: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  productPrice: number;
}

export class BuyersProductsUpdate {
  @Expose()
  @IsMongoId()
  id: string;

  @Expose()
  @IsString()
  sn: string;

  @Expose()
  @IsString()
  postCarbonChangeDate: string;

  @Expose()
  @IsString()
  installationDate: string;

  @Expose()
  @IsString()
  nextServiceDate: string;
}

export class UpdateBuyerValidator {
  @IsString({ message: 'First Name must be string' })
  @IsNotEmpty({ message: 'First Name should not be empty' })
  firstName: string;

  @IsString({ message: 'Last Name must be string' })
  @IsNotEmpty({ message: 'Last Name should not be empty' })
  lastName: string;

  @IsString({ message: 'Phone Number must be string' })
  @IsNotEmpty({ message: 'Phone Number should not be empty' })
  phoneNumber: string;

  @Expose()
  @Type(() => BuyersProductsUpdate)
  @ValidateNested()
  products: BuyersProductsUpdate[];

  @Expose()
  @Type(() => AddressValidator)
  @ValidateNested()
  address: Address;
}

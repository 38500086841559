import React from 'react';
import phoneIcon from '../../../../../assets/icons/phone-icon.svg';
import messageIcon from '../../../../../assets/icons/message-icon.svg';

export function List(): JSX.Element {
  return (
    <div className="table-responsive">
      <table>
        <tr className="list-headings">
          <th>Customer Name</th>
          <th>Address</th>
          <th>Pending Payments</th>
          <th>Task Assign</th>
          <th />
        </tr>
        <tr>
          <td>Bilal</td>
          <td>H #85 New Street Colony Fts</td>
          <td>
            <button className="btn btn-nill" type="button">
              Nill
            </button>
          </td>
          <td>
            <button className="btn btn-primary-light" type="button">
              Assigned
            </button>
          </td>
          <td className="d-flex more-icons">
            <button className="btn btn-success" type="button">
              <img src={phoneIcon} alt="" />
            </button>
            <button className="btn msg-icon" type="button">
              <img src={messageIcon} alt="" />
            </button>
          </td>
        </tr>
        <tr>
          <td>Rashid</td>
          <td>H #85 Street Rahmet Hospital foods Fts</td>
          <td>
            <button
              className="btn btn-danger pending-payments-btn"
              type="button">
              4500
            </button>
          </td>
          <td>
            <button className="btn btn-primary" type="button">
              Assign to
            </button>
          </td>
          <td className="d-flex more-icons">
            <button className="btn btn-success" type="button">
              <img src={phoneIcon} alt="" />
            </button>
            <button className="btn msg-icon" type="button">
              <img src={messageIcon} alt="" />
            </button>
          </td>
        </tr>
      </table>
    </div>
  );
}

import React from 'react';
import placeholder from '../../../../assets/images/placeholder.png';
import cameraIcon from '../../../../assets/icons/camera-icon.svg';
import { useAuthContext } from '../../../contexts';

export function Settings(): JSX.Element {
  const authContext = useAuthContext();
  return (
    <div className="content edit-profile">
      <form>
        <h1>Profile</h1>
        <div className="file-input-section">
          <img src={placeholder} className="placeholder-img" alt="" />
          <div className="file-input">
            <label htmlFor="file" className="form-label file-label">
              <img src={cameraIcon} className="edit-profile-label" alt="" />
              <input type="file" className="form-control" id="file" hidden />
            </label>
          </div>
        </div>
        <div className="row mt-3 gx-3">
          <div className="mb-1 col">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              value={authContext.user.firstName}
            />
          </div>
          <div className="mb-1 col">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Email Address"
              value={authContext.user.email}
            />
          </div>
        </div>
        <div className="row mt-3 gx-3">
          <div className="mb-3 col">
            <label htmlFor="phoneNumber" className="form-label">
              Phone Number
            </label>
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              id="phoneNumber"
              placeholder="Phone Number"
              value={authContext.user.phoneNumber}
            />
          </div>
          <div className="mb-3 col">
            <label htmlFor="shop_address" className="form-label">
              Shop Address
            </label>
            <input
              type="text"
              className="form-control"
              name="shop_address"
              id="shop_address"
              placeholder="Shop Address"
            />
          </div>
        </div>
        <div className="mt-2">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  Home,
  BuyersView,
  ExpertView,
  PaymentsView,
  Products,
  ChatView,
  Settings
} from '../../views/desktop';

export function Router(): JSX.Element {
  return (
    <Switch>
      <Route component={Home} path="/home" />
      <Route component={BuyersView} path="/buyers" />
      <Route component={ExpertView} path="/experts" />
      <Route component={PaymentsView} path="/payments" />
      <Route component={Products} path="/products" />
      <Route component={ChatView} path="/chat" />
      <Route component={Settings} path="/settings" />
      <Redirect to="/home" />
    </Switch>
  );
}

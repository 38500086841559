import React from 'react';
import { Header, Navbar } from '../../components';
import { Router } from './Router';

export function MainLayout(): JSX.Element {
  return (
    <div className="wrapper">
      <Navbar />
      <div className="main-content">
        <Header />
        <Router />
      </div>
    </div>
  );
}

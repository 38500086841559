import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { listOrderThunk, makePaymentThunk } from '../../thunks';
import { OrderPopulatedInterface } from '../../shared/interfaces';

interface UserFilterState {
  data: OrderPopulatedInterface[];
  loading: boolean;
  deleting: string;
  created: string;
  error: any;
  statusCode: number;
}

const initialState: UserFilterState = {
  data: [],
  loading: false,
  deleting: null,
  created: null,
  error: null,
  statusCode: null
};

export const orderSlice = createSlice({
  name: 'create-order-slice',
  initialState,
  reducers: {
    makePayment: (state, action: PayloadAction<OrderPopulatedInterface>) => {
      state.data = state.data.map((order) => {
        if (order.id === action.payload.id) {
          return {
            ...order,
            ...action.payload
          };
        }
        return order;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listOrderThunk.pending, (state) => {
        state.loading = !state.data.length;
        state.error = null;
      })
      .addCase(listOrderThunk.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(listOrderThunk.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
        state.loading = false;
      });

    builder.addCase(makePaymentThunk.fulfilled, (state, { payload }) => {
      state.data = state.data.map((order) => {
        if (order.id === (payload as OrderPopulatedInterface).id) {
          return {
            ...order,
            ...(payload as OrderPopulatedInterface)
          };
        }
        return order;
      });
      state.loading = false;
      state.error = null;
    });
  }
});

export const { makePayment } = orderSlice.actions;

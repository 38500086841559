import React from 'react';
import arrowGreen from '../../../assets/icons/arrow-green.svg';
import percentage from '../../../assets/images/percentage.svg';

interface Props {
  title: string;
  variant: 'primary' | 'success' | 'light' | 'warning';
  statistics: number;
}

export const DashboardCard = (props: Props): JSX.Element => {
  return (
    <div
      className={
        props.variant === 'primary'
          ? 'payment-card primary-bg d-flex justify-content-between align-items-center'
          : props.variant === 'success'
          ? 'payment-card success-bg d-flex justify-content-between align-items-center'
          : props.variant === 'light'
          ? 'payment-card light-bg d-flex justify-content-between align-items-center'
          : props.variant === 'warning'
          ? 'payment-card warning-bg d-flex justify-content-between align-items-center'
          : ''
      }>
      <div className="details">
        <h1>{props.title}</h1>
        <h2>{props.statistics}</h2>
      </div>
      <div className="percent d-flex flex-column align-items-end">
        <img src={arrowGreen} alt="" />
        <img src={percentage} alt="" />
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import csv from 'jquery-csv';

interface CSVObject {
  [key: string]: string;
}

export class CsvParser {
  static async toArrays(csvData: string): Promise<string[][]> {
    return new Promise((resolve, reject) => {
      csv.toArrays(csvData, {}, (err: unknown, data: string[][]) => {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve(data);
        }
      });
    });
  }

  static async toArray(csvData: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      csv.toArray(csvData, {}, (err: unknown, data: string[]) => {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve(data);
        }
      });
    });
  }

  static async toObjects<T = CSVObject>(csvData: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      csv.toObjects(csvData, {}, (err: unknown, data: T[]) => {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve(data);
        }
      });
    });
  }

  static async fromArrays(csvData: string[][]): Promise<string> {
    return new Promise((resolve, reject) => {
      csv.fromArrays(csvData, {}, (err: any, data: string) => {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve(data);
        }
      });
    });
  }

  static async fromObjects(csvData: CSVObject[]): Promise<string> {
    return new Promise((resolve, reject) => {
      csv.fromObjects(csvData, {}, (err: unknown, data: string) => {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve(data);
        }
      });
    });
  }
}

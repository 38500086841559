import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import crossIcon from '../../../../../../assets/icons/cross-icon.svg';
import crossIconblur from '../../../../../../assets/icons/cross-icon-blur.svg';
import { ProductsValidator } from '../../../../../../validators';
import { FormikValidator } from '../../../../../../shared/utility';
import { useFormik } from 'formik';
import { API } from '../../../../../../api';
import { FormikErrorMessage } from '../../../../../components';
import {
  ProductCategory,
  ProductType,
  ProductWarranty
} from '../../../../../../shared/interfaces';
import {
  productsInstallableThunk,
  productsReplaceableThunk
} from '../../../../../../thunks';
import { useDispatch } from '../../../../../../store';

interface Props {
  open: boolean;
  onClose: () => void;
}

export function NewProductDialog(props: Props): JSX.Element {
  const [images, setImages] = useState<string[]>([]);
  const handleClose = () => props.onClose();
  const dispatch = useDispatch();
  const formik = useFormik<ProductsValidator>({
    initialValues: {
      title: '',
      description: '',
      type: null,
      category: null,
      price: null,
      discountedPrice: null,
      images: [],
      warrantyPeriod: 0
    },

    onSubmit(values, formikHelpers): void | Promise<any> {
      API.Products.create({
        ...values,
        category:
          values.type === ProductType.Installable
            ? ProductCategory.Installable
            : values.category
      })
        .then((response) => {
          console.log(response);
          props.onClose();
          dispatch(productsReplaceableThunk());
          dispatch(productsInstallableThunk());
        })
        .catch((error) => {
          formikHelpers.setErrors(FormikValidator.serverErrors(error.response));
          console.error(error);
        });
    },

    validate: FormikValidator.validator(ProductsValidator)
  });

  useEffect(() => {
    formik.setFieldValue('images', images, true);
  }, [images]);

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const { files } = target;
    if (files.length) {
      const file = files[0];
      API.Media.upload(file)
        .then((response) => {
          setImages((state) => [
            ...state,
            API.Media.getFileByName(response.data.filename)
          ]);
        })
        .catch(console.log);
    }
  };
  // const handleInput = (e: any) => {
  //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
  // };
  const handleRemoveImage = (image: string) => () => {
    setImages((state) => state.filter((item) => item !== image));
  };
  const handleChangeWarranty = (event: ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue(
      event.currentTarget.name,
      Number(event.currentTarget.value),
      true
    );
  };
  return (
    <div>
      <Modal centered className="modal" show={props.open} onHide={handleClose}>
        <div className="modal-container">
          <span className="close-modal" onClick={handleClose}>
            <img src={crossIcon} alt="" />
          </span>
          <h1>Add Product</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-1 mt-3 col">
              <label htmlFor="title" className="form-label">
                Product Name
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                placeholder="Product Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormikErrorMessage
                formik={formik}
                name="title"
                render={(error) => <span className="error">{error}</span>}
              />
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-3 mt-3 col">
                <label htmlFor="type" className="form-label">
                  Type
                </label>
                <select
                  id="type"
                  name="type"
                  className="form-select form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}>
                  <option value={null} disabled selected>
                    Select Role
                  </option>
                  <option value={ProductType.Installable}>Installable</option>
                  <option value={ProductType.Replaceable}>Replaceable</option>
                </select>
                <FormikErrorMessage
                  formik={formik}
                  name="type"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              {formik.values.type === ProductType.Replaceable && (
                <div className="mb-3 mt-3 col">
                  <label htmlFor="category" className="form-label">
                    Category
                  </label>
                  <select
                    id="category"
                    name="category"
                    className="form-select form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}>
                    <option value={null} disabled selected>
                      Select Category
                    </option>
                    <option value={ProductCategory.Accessories}>
                      Accessories
                    </option>
                    <option value={ProductCategory.Filters}>Filters</option>
                    <option value={ProductCategory.Minerals}>Minerals</option>
                    <option value={ProductCategory.PostCarbon}>
                      Post Carbon
                    </option>
                  </select>
                  <FormikErrorMessage
                    formik={formik}
                    name="category"
                    render={(error) => <span className="error">{error}</span>}
                  />
                </div>
              )}
              {formik.values.type === ProductType.Installable && (
                <div className="mb-3 mt-3 col">
                  <label htmlFor="warrantyPeriod" className="form-label">
                    Warranty
                  </label>
                  <select
                    id="warrantyPeriod"
                    name="warrantyPeriod"
                    className="form-select form-control"
                    onChange={handleChangeWarranty}
                    onBlur={formik.handleBlur}>
                    <option value={null} disabled selected>
                      Select Warranty
                    </option>
                    <option value={ProductWarranty['non-warranty']}>
                      Non warranty
                    </option>
                    <option value={ProductWarranty['six-month']}>
                      6 Month
                    </option>
                    <option value={ProductWarranty['one-year']}>1 Year</option>
                  </select>
                  <FormikErrorMessage
                    formik={formik}
                    name="warrantyPeriod"
                    render={(error) => <span className="error">{error}</span>}
                  />
                </div>
              )}
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-3 col">
                <label htmlFor="price" className="form-label">
                  Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="price"
                  id="price"
                  placeholder="Price"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="price"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              <div className="mb-3 col">
                <label htmlFor="discountedPrice" className="form-label">
                  Discount Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="discountedPrice"
                  id="discountedPrice"
                  placeholder="Discount Price"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="discountedPrice"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-3 col">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="description"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="mb-3 col media-file">
              <h1>Upload Images</h1>
              <div className="media">
                {!!images.length && (
                  <div className="added-imgs">
                    {images.map((image, index) => (
                      <div className="added-img" key={index}>
                        <img src={image} alt="" className="file" />
                        <button
                          type="button"
                          className="btn cross-icon"
                          onClick={handleRemoveImage(image)}>
                          <img src={crossIconblur} alt="" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                <h1>Drag and drop file here</h1>
                <p>File supported PDF, JPG and PNG</p>
                <label htmlFor="images" className="form-label file-label">
                  Browse
                  <input
                    type="file"
                    className="form-control"
                    id="images"
                    name="images"
                    accept="image/*"
                    onChange={handleChangeFile}
                    hidden
                    onBlur={formik.handleBlur}
                  />
                </label>
                <p>Maximum Size 5MB</p>
              </div>
              <FormikErrorMessage
                formik={formik}
                name="images"
                render={(error) => <span className="error">{error}</span>}
              />
            </div>
            <div className="mt-2">
              <button className="btn btn-primary" type="submit">
                Save Product
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

import React, { useState } from 'react';
import { DesktopList, NewExpertDialog } from './components';

export function ExpertView(): JSX.Element {
  const [showCreate, setShowCreate] = useState(false);

  const handleShowCreate = () => {
    setShowCreate(true);
  };
  const handleCloseCreate = () => {
    setShowCreate(false);
  };

  return (
    <div className="content">
      <div className="experts-list same-lists">
        <div className="list-header list-header-row d-flex justify-content-between align-items-center">
          <h1>Experts</h1>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleShowCreate}>
            Add Expert
          </button>
        </div>
        <DesktopList />
      </div>
      {/* Add Expert Dialog */}
      <NewExpertDialog open={showCreate} onClose={handleCloseCreate} />
    </div>
  );
}

import React from 'react';
import {
  // NotificationsDropdown,
  UserDropdown
} from './components';

export function Header(): JSX.Element {
  return (
    <header className="header d-flex justify-content-end align-items-center">
      <div className="d-flex">
        {/* <div className="notification-point"> */}
        {/*  <NotificationsDropdown /> */}
        {/* </div> */}
        <div className="user-dropdown">
          <UserDropdown />
        </div>
      </div>
    </header>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import editIcon from '../../../../../../assets/icons/edit-icon.svg';
import { UpdateBuyerDialog } from '../UpdateBuyerDialog';
import { useDispatch, useSelector } from '../../../../../../store';
import { deleteUserThunk, usersThunk } from '../../../../../../thunks';
import {
  Address,
  ProductWarranty,
  UserInterface,
  UserRole
} from '../../../../../../shared/interfaces';
import { Dropdown } from 'react-bootstrap';
import moreIcon from '../../../../../../assets/icons/more-icon.svg';
import moment from 'moment';

interface Props {
  search: string;
}

export function DesktopList(props: Props): JSX.Element {
  const [showUpdate, setShowUpdate] = useState<string>(null);
  const users = useSelector((state) => state.usersList);
  const today = new Date();

  const buyers = useMemo(() => {
    const searchText = props.search
      ? props.search
          .trim()
          .split(' ')
          .filter((item) => !!item)
          .join('|')
      : props.search.trim();
    const regex = new RegExp(searchText, 'gi');
    const filter = (user: UserInterface) => {
      return (
        !searchText ||
        regex.test(user.firstName) ||
        regex.test(user.lastName) ||
        regex.test(user.phoneNumber) ||
        (user?.address?.locality && regex.test(user.address.locality))
      );
    };
    return users.buyers.filter(filter);
  }, [users.buyers, props.search]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(usersThunk({ role: [UserRole.Buyer] }));
  }, []);

  const handleShowUpdate = (id: string) => () => {
    setShowUpdate(id);
  };
  const handleCloseUpdate = () => {
    setShowUpdate(null);
  };
  const handleConfirmDelBuyer = (id: string) => () => {
    dispatch(deleteUserThunk(id));
    alert('Are you sure you want to delete this Buyer!');
    dispatch(usersThunk({ role: [UserRole.Buyer] }));
  };

  const hasLocation = (address: Address) =>
    address?.location &&
    address?.location?.coordinates &&
    Array.isArray(address?.location?.coordinates) &&
    address?.location?.coordinates.some(Boolean);

  const directionUrl = (address: Address) =>
    `https://maps.google.com/?q=${address?.location?.coordinates[1]},${address?.location?.coordinates[0]}`;
  return (
    <>
      <div className="table-responsive">
        <table>
          <tr className="list-headings">
            <th>Customer Name</th>
            <th>Address</th>
            <th>Products</th>
            <th />
          </tr>
          {buyers.map((user) => (
            <tr key={user.id}>
              <td>
                {user.firstName} {user.lastName}
              </td>
              <td>
                {hasLocation(user.address) ? (
                  <a
                    target="_blank"
                    href={directionUrl(user.address)}
                    rel="noreferrer">
                    {user?.address?.locality}
                  </a>
                ) : (
                  user?.address?.locality
                )}
              </td>

              <td>
                {!!user.products && !!user.products.length && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {user.products
                      .filter((item) => item.product)
                      .map((item) => (
                        <div key={item.id}>
                          <div style={{ display: 'inline-flex', gap: 5 }}>
                            <div>{item.product.title}</div>
                            <div style={{ color: 'red', fontSize: 12 }}>
                              {item.sn}
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: '13px',
                                margin: 0
                              }}>
                              Warranty:{' '}
                              {item.product.warrantyPeriod ===
                                ProductWarranty['non-warranty'] && (
                                <span style={{ fontWeight: 'normal' }}>
                                  No Warranty
                                </span>
                              )}
                              {item.product.warrantyPeriod ===
                                ProductWarranty['six-month'] && (
                                <span style={{ fontWeight: 'normal' }}>
                                  6 Month
                                </span>
                              )}
                              {item.product.warrantyPeriod ===
                                ProductWarranty['one-year'] && (
                                <span style={{ fontWeight: 'normal' }}>
                                  1 Year
                                </span>
                              )}
                              {/*{item.product.warrantyPeriod ===*/}
                              {/*  ProductWarranty['non-warranty'] && (*/}
                              {/*  <span style={{ fontWeight: 'normal' }}>*/}
                              {/*    No Warranty*/}
                              {/*  </span>*/}
                              {/*)}*/}
                              {/*{item.product.warrantyPeriod !==*/}
                              {/*  ProductWarranty['non-warranty'] &&*/}
                              {/*  new Date(item.warrantyExpired).getTime() >*/}
                              {/*    today.getTime() && (*/}
                              {/*    <span style={{ fontWeight: 'normal' }}>*/}
                              {/*      ({moment(item.warrantyExpired).calendar()})*/}
                              {/*    </span>*/}
                              {/*  )}*/}
                              {item.product.warrantyPeriod !==
                                ProductWarranty['non-warranty'] &&
                                new Date(item.warrantyExpired).getTime() <
                                  today.getTime() && (
                                  <span style={{ fontWeight: 'normal' }}>
                                    {' '}
                                    (Expired at{' '}
                                    {moment(item.warrantyExpired).calendar()})
                                  </span>
                                )}
                            </p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                margin: 0
                              }}>
                              Next Service:{' '}
                              <span style={{ fontWeight: 'normal' }}>
                                {' '}
                                {moment(item.nextServiceDate).calendar()}
                              </span>
                            </p>

                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                margin: 0
                              }}>
                              Post Carbon:{' '}
                              <span style={{ fontWeight: 'normal' }}>
                                {' '}
                                {moment(item.postCarbonChangeDate).calendar()}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </td>
              <td className="d-flex more-icons">
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={handleShowUpdate(user.id)}>
                  <img src={editIcon} alt="" />
                </button>
                <button className="btn eye-icon" type="button">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdown-btn"
                      variant="success"
                      id="dropdown-basic">
                      <img src={moreIcon} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={handleConfirmDelBuyer(user.id)}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </button>
              </td>
            </tr>
          ))}
          {!buyers.length && !users.loading && (
            <tr>
              <td colSpan={4} align="center" style={{ fontWeight: 'bold' }}>
                No Buyers Found
              </td>
            </tr>
          )}
          {!buyers.length && users.loading && (
            <tr>
              <td colSpan={4} align="center" style={{ fontWeight: 'bold' }}>
                Loading Data
              </td>
            </tr>
          )}
        </table>
      </div>
      {/* Update Buyer Dialog */}
      {!!showUpdate && (
        <UpdateBuyerDialog
          open={!!showUpdate}
          id={showUpdate}
          onClose={handleCloseUpdate}
        />
      )}
    </>
  );
}

//= ================CHECK BOXES==================
// import checkbox from '../../../assets/icons/checkbox.svg';
// import checkBoxOutline from '../../../assets/icons/checkboxoutline.svg';
// import checkboxNewtrul from '../../../assets/icons/checkboxnewtrul.svg';
// const [selected, setSelected] = useState<string[]>([]);

// const onChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
//   event.persist();
//   const target = event.currentTarget;
//   const { value } = target;
//   const { checked } = target;
//   const { name } = target;
//
//   if (name === 'all') {
//     if (checked) {
//       setSelected(getCurrentClinicStaff().map((item) => item.id));
//     } else {
//       setSelected([]);
//     }
//   } else if (checked) {
//     setSelected([...selected, value]);
//   } else {
//     setSelected(selected.filter((item) => item !== value));
//   }
// };

// <th>
//   <label className="tag-button-label">
//     <input
//         name="all"
//         onChange={onChangeCheckBox}
//         checked={selected.length === getCurrentClinicStaff().length}
//         type="checkbox"
//     />
//     <div className="tag-button">
//       <div className="tag-icon checked">
//         <img src={checkbox} alt="" />
//       </div>
//       <div className="tag-icon unchecked">
//         {!!selected.length &&
//         selected.length !== getCurrentClinicStaff().length && (
//             <img src={checkboxNewtrul} alt="" />
//         )}
//         {!selected.length && <img src={checkBoxOutline} alt="" />}
//       </div>
//     </div>
//   </label>
// </th>

// <td>
//   <div className="flex">
//     <label className="tag-button-label">
//       <input
//           name="id"
//           onChange={onChangeCheckBox}
//           value={member.id}
//           checked={selected.includes(member.id)}
//           type="checkbox"
//       />
//       <div className="tag-button">
//         <div className="tag-icon checked">
//           <img src={checkbox} alt="" />
//         </div>
//         <div className="tag-icon unchecked">
//           <img src={checkboxOutline} alt="" />
//         </div>
//       </div>
//     </label>
//   </div>
// </td>

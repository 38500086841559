import React, { useEffect, useState } from 'react';
import { UpdateExpertDialog } from '../UpdateExpertDialog';
import editIcon from '../../../../../../assets/icons/edit-icon.svg';
import { Dropdown } from 'react-bootstrap';
import moreIcon from '../../../../../../assets/icons/more-icon.svg';
import { useDispatch, useSelector } from '../../../../../../store';
import { deleteUserThunk, usersThunk } from '../../../../../../thunks';
import { UserRole } from '../../../../../../shared/interfaces';
import { ChangePasswordDialog } from '../ChangePasswordDialog';

export function DesktopList(): JSX.Element {
  const [showUpdateExpert, setShowUpdateExpert] = useState<string>(null);
  const [showChangePassword, setShowChangePassword] = useState<string>(null);
  const handleShowUpdate = (id: string) => () => {
    setShowUpdateExpert(id);
  };
  const handleCloseUpdate = () => {
    setShowUpdateExpert(null);
  };

  const handleShowChangePassword = (id: string) => () => {
    setShowChangePassword(id);
  };

  const handleCloseChangePassword = () => {
    setShowChangePassword(null);
  };

  const dispatch = useDispatch();
  const experts = useSelector((state) => state.usersList);

  useEffect(() => {
    dispatch(usersThunk({ role: [UserRole.Expert] }));
  }, []);

  const handleConfirmDelExpert = (id: string) => () => {
    dispatch(deleteUserThunk(id));
    dispatch(usersThunk({ role: [UserRole.Expert] }));
    alert('Are you sure you want to delete this Expert!');
  };

  return (
    <>
      <div className="table-responsive">
        <table>
          <tr className="list-headings">
            <th>Customer Name</th>
            <th>Email Address</th>
            <th>Address</th>

            <th />
          </tr>
          {experts.experts.map((expert) => (
            <tr key={expert.id}>
              <td>{expert.firstName}</td>
              <td>{expert.email}</td>
              <td>{expert.address?.locality}</td>
              <td className="d-flex more-icons">
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={handleShowUpdate(expert.id)}>
                  <img src={editIcon} alt="" />
                </button>
                <button className="btn eye-icon" type="button">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdown-btn"
                      variant="success"
                      id="dropdown-basic">
                      <img src={moreIcon} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={handleShowChangePassword(expert.id)}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={handleConfirmDelExpert(expert.id)}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
      {/* Update Expert Dialog */}
      {!!showUpdateExpert && (
        <UpdateExpertDialog
          open={!!showUpdateExpert}
          id={showUpdateExpert}
          onClose={handleCloseUpdate}
        />
      )}

      {!!showChangePassword && (
        <ChangePasswordDialog
          open={!!showChangePassword}
          id={showChangePassword}
          onClose={handleCloseChangePassword}
        />
      )}
    </>
  );
}

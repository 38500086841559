import React from 'react';
import { AxiosPromise } from 'axios';
import { axiosInstance } from './axiosInstance';
import { FileUploadResponse } from '../shared/interfaces';

export class Media extends React.Component {
  static upload(
    file: File,
    onUploadProgress?: (progressEvent: ProgressEvent) => void
  ): AxiosPromise<FileUploadResponse> {
    const formData = new FormData();
    formData.set('file', file);
    return axiosInstance({
      url: '/media/upload',
      method: 'post',
      data: formData,
      onUploadProgress
    });
  }

  static getFileByName(filename: string): string {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/media/${filename}`;
    return url.href;
  }
}

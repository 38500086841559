import { loadScript } from './loadScript';

export async function loadGoogleMapScript(): Promise<any> {
  const url = new URL('https://maps.googleapis.com/maps/api/js');
  url.searchParams.set('key', process.env.REACT_APP_GOOGLE_MAP_API_KEY);
  url.searchParams.set('libraries', 'places');
  url.searchParams.set('v', 'weekly');
  try {
    await loadScript({
      id: 'google-map-script',
      src: url.href,
      async: true
    });
    console.log('Google Map Loaded');
  } catch (e) {
    console.error(e);
  }
}

import {
  IsArray,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsUrl,
  ValidateIf
} from 'class-validator';
import { ProductCategory, ProductType } from '../shared/interfaces';
import { Expose } from 'class-transformer';
import { ValidateCallback } from '../shared/custom-validators';

@Expose()
export class ProductsValidator {
  @IsString({ message: 'Product Name must be string' })
  @IsNotEmpty({ message: 'Product Name should not be empty' })
  title: string;

  @IsString({ message: 'Product Name must be string' })
  @IsNotEmpty({ message: 'Product Name should not be empty' })
  description: string;

  @IsNotEmpty({ message: 'Type should not be empty' })
  type: ProductType;

  @IsNotEmpty({ message: 'Type should not be empty' })
  @ValidateIf((object) => object.type === ProductType.Replaceable)
  category: ProductCategory;

  @IsNumber()
  @IsNotEmpty({ message: 'Price should not be empty' })
  price: number;

  @IsNumber()
  @IsNotEmpty({ message: 'Discount Price should not be empty' })
  @ValidateCallback((value, object) => value < object.price, {
    message: 'discounted price must be less then original price'
  })
  discountedPrice: number;

  @IsUrl({}, { each: true })
  @IsArray()
  @IsNotEmpty({ message: 'File should not be empty' })
  images: string[];

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  warrantyPeriod: number;
}

import React, { ChangeEvent, useEffect, useState } from 'react';
import { DesktopList, NewBuyerDialog } from './components';
import { useDispatch } from '../../../../store';
import {
  bulkCreateUserThunk,
  productsInstallableThunk,
  usersThunk
} from '../../../../thunks';
import { UserCsvData, UserRole } from '../../../../shared/interfaces';
import { isMongoId, isPhoneNumber } from 'class-validator';
import { CsvParser } from '../../../../shared/utility/csv-parser';
import { useSearch } from '../../../../shared/utility/useSearch';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-icon.svg';

export function BuyersView(): JSX.Element {
  const { searchText, onChangeText, value } = useSearch('');
  const [showNew, setShowNew] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersThunk({ role: [UserRole.Buyer] }));
    dispatch(productsInstallableThunk());
  }, []);

  const handleShowNew = () => {
    setShowNew(true);
  };
  const handleCloseNew = () => {
    setShowNew(false);
  };

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const input = event.currentTarget;
    const file = input.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const csvString: string = fileReader.result as string;
      const requiredHeaders = [
        'sn',
        'name',
        'phoneNumber',
        'product',
        'installationDate',
        'warrantyExpired',
        'postCarbonChangeDate'
      ];
      CsvParser.toObjects<UserCsvData>(csvString).then((csv) => {
        const csvFiltered = csv
          .filter((item) => {
            return Object.keys(item)
              .map((header) => header.trim())
              .every((header) => requiredHeaders.includes(header));
          })
          .filter((item) => {
            return Object.values(item)
              .map((item) => item.trim())
              .some(Boolean);
          })
          .filter((item) => {
            return isMongoId(item.product.trim());
          })
          .map((item) => {
            if (!item.phoneNumber.startsWith('0')) {
              if (isPhoneNumber(`+92${item.phoneNumber}`, 'PK')) {
                item.phoneNumber = `+92${item.phoneNumber}`;
              } else if (isPhoneNumber(`${item.phoneNumber}`, 'PK')) {
                item.phoneNumber = `${item.phoneNumber}`;
              } else {
                item.phoneNumber = `03000000000-${item.sn}`;
              }
            } else if (
              item.phoneNumber.startsWith('0') &&
              isPhoneNumber(`+92${item.phoneNumber}`, 'PK')
            ) {
              item.phoneNumber = `+92${item.phoneNumber}`;
            }
            item.product = item.product.trim();
            item.phoneNumber = item.phoneNumber.trim();
            item.installationDate = item.installationDate.trim();
            item.warrantyExpired = item.warrantyExpired.trim();
            item.sn = item.sn.trim();
            return item;
          });
        dispatch(bulkCreateUserThunk(csvFiltered));
      });
    };
    fileReader.readAsText(file);
  };

  return (
    <div className="content">
      <div className="buyers-list same-lists">
        <div className="list-header list-header-row d-flex justify-content-between align-items-center">
          <h1 className="flex-shrink-0" style={{ marginRight: '10px' }}>
            Buyers
          </h1>
          <div className="d-flex flex-grow-1" style={{ height: 60 }}>
            <div className="combine-input d-flex align-items-center form-control">
              <button className="btn" type="submit">
                <SearchIcon />
              </button>
              <input
                type="text"
                name="text"
                className="form-control"
                value={value}
                onChange={(event) => {
                  onChangeText(event.currentTarget.value);
                }}
                id="search"
                placeholder="Keywords"
              />
            </div>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <label
              htmlFor="buyer-list"
              className="btn btn-primary"
              style={{
                display: 'none',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <input
                className="btn btn-primary"
                id="buyer-list"
                onChange={handleChangeFile}
                type="file"
                hidden
              />
              <span style={{ fontSize: 14 }}>Add Bulk Buyer</span>
            </label>

            <button
              className="btn btn-primary"
              type="button"
              onClick={handleShowNew}>
              Add Buyer
            </button>
          </div>
        </div>
        <DesktopList search={searchText} />
      </div>
      {/* Add Buyer Dialog */}
      <NewBuyerDialog open={showNew} onClose={handleCloseNew} />
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import {
  deleteProductThunk,
  productsInstallableThunk,
  productsListThunk,
  productsReplaceableThunk
} from '../../thunks';
import { ProductsInterface, ProductType } from '../../shared/interfaces';

interface ProductsState {
  installable: ProductsInterface[];
  replaceable: ProductsInterface[];
  searchText: string;
  loading: boolean;
  deleting: string;
  created: string;
  error: any;
}

const initialState: ProductsState = {
  installable: [],
  replaceable: [],
  searchText: null,
  loading: false,
  deleting: null,
  created: null,
  error: null
};
export const productsSlice = createSlice({
  name: 'products-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Products
    builder
      .addCase(productsInstallableThunk.pending, (state) => {
        state.loading = !state.installable.length;
        state.error = null;
      })
      .addCase(productsInstallableThunk.fulfilled, (state, { payload }) => {
        state.installable = payload ?? [];
        state.loading = false;
        state.error = null;
      })
      .addCase(productsInstallableThunk.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
        state.loading = false;
      });

    builder
      .addCase(productsReplaceableThunk.pending, (state) => {
        state.loading = !state.replaceable.length;
        state.error = null;
      })
      .addCase(productsReplaceableThunk.fulfilled, (state, { payload }) => {
        state.replaceable = payload ?? [];
        state.loading = false;
        state.error = null;
      })
      .addCase(productsReplaceableThunk.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
        state.loading = false;
      });
    builder
      .addCase(productsListThunk.pending, (state) => {
        state.loading = !state.replaceable.length;
        state.error = null;
      })
      .addCase(productsListThunk.fulfilled, (state, { payload }) => {
        state.replaceable = (payload ?? []).filter(
          (item: ProductsInterface) => item.type === ProductType.Replaceable
        );

        state.installable = (payload ?? []).filter(
          (item: ProductsInterface) => item.type === ProductType.Installable
        );

        state.loading = false;
        state.error = null;
      })
      .addCase(productsListThunk.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
        state.loading = false;
      });
    // Delete Product
    builder.addCase(deleteProductThunk.pending, (state) => {
      state.error = null;
    });
    builder.addCase(deleteProductThunk.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteProductThunk.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
      state.loading = false;
    });
  }
});

import React, { useEffect } from 'react';
import {
  ChangeFilterList,
  DashboardCard,
  RoPlantsList
} from '../../../components';
import { useDispatch, useSelector } from '../../../../store';
import { ordersSummaryThunk, statisticsThunk } from '../../../../thunks';

export function Home(): JSX.Element {
  const dispatch = useDispatch();
  const statistics = useSelector((state) => state.statistics);
  const summary = useSelector((state) => state.statistics);
  useEffect(() => {
    const date = new Date();
    const from = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );
    const to = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );
    dispatch(
      statisticsThunk({
        timestamp: { from: from.toISOString(), to: to.toISOString() }
      })
    );
    dispatch(ordersSummaryThunk());
  }, []);

  return (
    <div className="content home">
      <div className="payment-cards">
        {!!statistics.users && !!statistics.orders && (
          <>
            <DashboardCard
              title="Total Pending expired post carbon"
              variant="primary"
              statistics={statistics.users.pendingExpirePostCarbon}
            />

            <DashboardCard
              title="Pending Tasks"
              variant="warning"
              statistics={statistics.users.pendingTasks}
            />
            <DashboardCard
              title="Clients"
              variant="primary"
              statistics={statistics.users.buyers}
            />
            <DashboardCard
              title="Total Earning"
              variant="success"
              statistics={summary.orders.paid}
            />
            <DashboardCard
              title="Upcoming Tasks"
              variant="primary"
              statistics={statistics.users.upcomingTasks}
            />
            <DashboardCard
              title="Under Warranty Clients' details"
              variant="success"
              statistics={statistics.users.warrantyExpired}
            />
            <DashboardCard
              title="All Tasks"
              variant="warning"
              statistics={statistics.users.totalTasks}
            />
            <DashboardCard
              title="Total Pending"
              variant="light"
              statistics={summary.orders.pending}
            />
          </>
        )}
      </div>
      <ChangeFilterList />
      <RoPlantsList />
    </div>
  );
}

import React from 'react';
import { Chat, ChatLogs } from './components';

export function ChatView():JSX.Element {
  return (
    <div className="content d-flex chat-view">
      <ChatLogs />
      <Chat />
    </div>
  );
}

import axios, { CancelTokenSource } from 'axios';
import { Cookies } from '../shared/utility';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const pendingRequests: Record<string, CancelTokenSource> = {};

axiosInstance.interceptors.request.use((config) => {
  if (config) {
    const token = Cookies.get('access-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  // if (pendingRequests[config.url]) {
  //   pendingRequests[config.url].cancel();
  // }
  const cancelTokenSource = axios.CancelToken.source();
  config.cancelToken = cancelTokenSource.token;
  pendingRequests[config.url] = cancelTokenSource;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    delete pendingRequests[response.config.url];
    return response;
  },
  (error) => {
    if (!axios.isCancel(error)) {
      delete pendingRequests[error.config.url];
    }

    return Promise.reject(error);
  }
);

import { ProductsInterface } from './product.interface';

export enum UserRole {
  Admin = 'admin',
  Expert = 'expert',
  Buyer = 'buyer'
}

export interface UserProductInterface {
  product: ProductsInterface;
  installationDate: string;
  servicedAt: string;
  nextServiceDate: string;
  createdAt: string;
  updatedAt: string;
  postCarbonChangeDate: string;
  warrantyExpired: string;
  sn: string;
  id: string;
}

export interface UserInterface {
  id: string;
  started?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: Address;
  roles: UserRole[];
  token?: string;
  payment: number;
  products: UserProductInterface[];
  installationDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface Login {
  email: string;
  password: string;
  realm: UserRole;
}

export interface Address {
  locality: string;
  location: Location;
}

export interface Location {
  type: 'Point';
  coordinates: number[];
}

export interface UserFilterInterface {
  role: UserRole[];
  nextServiceDate?: {
    from?: string;
    to?: string;
  };
  installationDate?: {
    from?: string;
    to?: string;
  };
  servicedAt?: {
    from?: string;
    to?: string;
  };
}

export interface UserCsvData {
  name: string;
  sn: string;
  phoneNumber: string;
  product: string;
  installationDate: string;
  warrantyExpired: string;
}

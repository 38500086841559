import { Auth } from './Auth';
import { Products } from './Products';
import { Media } from './Media';
import { Orders } from './Order';

export const API = {
  Auth,
  Products,
  Media,
  Orders
};

import React from 'react';
import { Modal } from 'react-bootstrap';
import crossIcon from '../../../../../../assets/icons/cross-icon.svg';
import { FormikConfig } from 'formik/dist/types';
import { ExpertsValidator } from '../../../../../../validators';
import { FormikValidator } from '../../../../../../shared/utility';
import { ErrorMessage, Form, Formik } from 'formik';
import { API } from '../../../../../../api';
import { UserRole } from '../../../../../../shared/interfaces';
import { useDispatch } from '../../../../../../store';
import { usersThunk } from '../../../../../../thunks';

interface Props {
  open: boolean;
  onClose: () => void;
}

export function NewExpertDialog(props: Props): JSX.Element {
  const handleClose = () => props.onClose();
  const dispatch = useDispatch();
  const formik: FormikConfig<ExpertsValidator> = {
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirm_password: '',
      address: {
        locality: '',
        location: {
          type: 'Point',
          coordinates: [0, 0]
        }
      },
      role: UserRole.Expert
    },
    onSubmit(values): void | Promise<any> {
      API.Auth.create(values)
        .then((response) => {
          console.log(response);
          dispatch(usersThunk({ role: [UserRole.Expert] }));
          handleClose();
        })
        .then((error) => {
          console.error(error);
        });
    },

    validate: FormikValidator.validator(ExpertsValidator)
  };
  return (
    <div>
      <Modal centered className="modal" show={props.open} onHide={handleClose}>
        <div className="modal-container">
          <span className="close-modal" onClick={handleClose}>
            <img src={crossIcon} alt="" />
          </span>
          <h1>Add New Expert</h1>
          <Formik {...formik}>
            {(formikProps) => (
              <Form>
                <div className="row mt-3 gx-3">
                  <div className="mb-1 col">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                    <ErrorMessage
                      name="firstName"
                      render={(error) => <span className="error">{error}</span>}
                    />
                  </div>
                  <div className="mb-1 col">
                    <label htmlFor="lastNames" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                    <ErrorMessage
                      name="lastName"
                      render={(error) => <span className="error">{error}</span>}
                    />
                  </div>
                </div>
                <div className="row mt-3 gx-3">
                  <div className="mb-1 col">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                    <ErrorMessage
                      name="email"
                      render={(error) => <span className="error">{error}</span>}
                    />
                  </div>
                  <div className="mb-3 col">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Phone Number"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      render={(error) => <span className="error">{error}</span>}
                    />
                  </div>
                  <div className="row mt-3 gx-3">
                    <div className="mb-3 col">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder="Password"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                      />
                      <ErrorMessage
                        name="password"
                        render={(error) => (
                          <span className="error">{error}</span>
                        )}
                      />
                    </div>
                    <div className="mb-3 col">
                      <label htmlFor="confirm_password" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirm_password"
                        id="confirm_password"
                        placeholder="Confirm Password"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                      />
                      <ErrorMessage
                        name="confirm_password"
                        render={(error) => (
                          <span className="error">{error}</span>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="address.locality" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="address.locality"
                    id="address.locality"
                    placeholder="Address"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                  />
                  <ErrorMessage
                    name="address.locality"
                    render={(error) => <span className="error">{error}</span>}
                  />
                </div>
                <div className="mt-2">
                  <button className="btn btn-primary" type="submit">
                    Add Expert
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import crossIcon from '../../../../../../assets/icons/cross-icon.svg';
import { getIn, useFormik } from 'formik';
import {
  UpdateBuyerValidator,
  UpdateExpertValidator
} from '../../../../../../validators';
import { API } from '../../../../../../api';
import { FormikValidator } from '../../../../../../shared/utility';
import { FormikErrorMessage } from '../../../../../components';
import { usersThunk } from '../../../../../../thunks';
import { UserInterface, UserRole } from '../../../../../../shared/interfaces';
import { useDispatch } from '../../../../../../store';
import moment from 'moment';

interface Props {
  id: string;
  open: boolean;
  onClose: () => void;
}

export function UpdateBuyerDialog(props: Props): JSX.Element {
  const handleClose = () => props.onClose();
  const [user, setUser] = useState<UserInterface>(null);
  const dispatch = useDispatch();
  const formik = useFormik<UpdateBuyerValidator>({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      products: [],
      address: {
        locality: '',
        location: null
      }
    },
    onSubmit(values): void | Promise<any> {
      console.log(values);
      API.Auth.updateById(props.id, {
        ...values,
        products: values.products.map((item) => {
          if (item.postCarbonChangeDate) {
            item.postCarbonChangeDate = new Date(
              item.postCarbonChangeDate
            ).toISOString();
          }
          if (item.nextServiceDate) {
            item.nextServiceDate = new Date(item.nextServiceDate).toISOString();
          }
          return item;
        })
      })
        .then((response) => {
          console.log(response);
          dispatch(usersThunk({ role: [UserRole.Buyer] }));
          handleClose();
        })
        .then((error) => {
          console.error(error);
        });
    },

    validate: FormikValidator.validator(UpdateBuyerValidator)
  });

  useEffect(() => {
    if (props.id) {
      API.Auth.getById(props.id).then((response) => {
        const userData = response.data;
        setUser(userData);
        formik.setValues({
          firstName: userData.firstName,
          lastName: userData.lastName,
          phoneNumber: userData.phoneNumber,
          products: userData.products.map((item) => {
            return {
              id: item.id,
              nextServiceDate: moment(item.nextServiceDate).format(
                moment.HTML5_FMT.DATE
              ),
              installationDate: moment(item.installationDate).format(
                moment.HTML5_FMT.DATE
              ),
              postCarbonChangeDate: moment(item.postCarbonChangeDate).format(
                moment.HTML5_FMT.DATE
              ),
              sn: item.sn
            };
          }),
          address: userData.address
        });
      });
    }
  }, [props.id, setUser]);
  return (
    <div>
      <Modal
        centered
        className="modal new-buyer"
        show={props.open}
        onHide={handleClose}>
        <div className="modal-container">
          <span className="close-modal" onClick={handleClose}>
            <img src={crossIcon} alt="" />
          </span>
          <h1>Edit Buyer</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="row mt-3 gx-3">
              <div className="mb-1 col">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="firstName"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              <div className="mb-1 col">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="lastName"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-1 col">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="phoneNumber"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-1 col">
                <label htmlFor="address.locality" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="address.locality"
                  id="address.locality"
                  placeholder="Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address.locality}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="address.locality"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            {!!user && (
              <div className="row mt-3 gx-3">
                {!!user.products &&
                  user.products.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          display: 'inline-flex',
                          width: '100%'
                        }}>
                        <img
                          src={item.product.images[0]}
                          style={{
                            width: 250,
                            height: 250,
                            objectFit: 'contain'
                          }}
                          alt="Image"
                        />
                        <div>
                          <p
                            className="text-dark mb-0 text-xl-start"
                            style={{ fontWeight: 'bold', fontSize: 12 }}>
                            {item.product.title}
                          </p>
                          <div className="form-group">
                            <label htmlFor={`products.${index}.sn`}>
                              Serial Number
                            </label>
                            <input
                              type="text"
                              style={{ height: '30px' }}
                              className="form-control"
                              id={`products.${index}.sn`}
                              name={`products.${index}.sn`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={getIn(
                                formik.values,
                                `products.${index}.sn`
                              )}
                              placeholder="Serial Number"
                            />
                            <FormikErrorMessage
                              formik={formik}
                              name={`products.${index}.sn`}
                              render={(error) => (
                                <span className="error">{error}</span>
                              )}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor={`products.${index}.installationDate`}>
                              Installation Date
                            </label>
                            <input
                              type="date"
                              style={{ height: '30px' }}
                              className="form-control"
                              id={`products.${index}.installationDate`}
                              name={`products.${index}.installationDate`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={getIn(
                                formik.values,
                                `products.${index}.installationDate`
                              )}
                              placeholder="Installation Date"
                            />
                            <FormikErrorMessage
                              formik={formik}
                              name={`products.${index}.installationDate`}
                              render={(error) => (
                                <span className="error">{error}</span>
                              )}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor={`products.${index}.nextServiceDate`}>
                              Next Service Date
                            </label>
                            <input
                              type="date"
                              style={{ height: '30px' }}
                              className="form-control"
                              id={`products.${index}.nextServiceDate`}
                              name={`products.${index}.nextServiceDate`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={getIn(
                                formik.values,
                                `products.${index}.nextServiceDate`
                              )}
                              placeholder="Next Service Date"
                            />
                            <FormikErrorMessage
                              formik={formik}
                              name={`products.${index}.nextServiceDate`}
                              render={(error) => (
                                <span className="error">{error}</span>
                              )}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor={`products.${index}.postCarbonChangeDate`}>
                              Next Post Carbon Change Date
                            </label>
                            <input
                              type="date"
                              style={{ height: '30px' }}
                              className="form-control"
                              id={`products.${index}.postCarbonChangeDate`}
                              name={`products.${index}.postCarbonChangeDate`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={getIn(
                                formik.values,
                                `products.${index}.postCarbonChangeDate`
                              )}
                              placeholder="Next Post Carbon Change Date"
                            />
                            <FormikErrorMessage
                              formik={formik}
                              name={`products.${index}.postCarbonChangeDate`}
                              render={(error) => (
                                <span className="error">{error}</span>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="mt-2">
              <button className="btn btn-primary" type="submit">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

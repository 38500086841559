import { IsEmail, IsNotEmpty, IsString } from 'class-validator';
import { UserRole } from '../shared/interfaces';

export class LoginFormValidator {
  @IsEmail({}, { message: 'Email must be valid' })
  @IsNotEmpty({ message: 'Email should not be empty' })
  email: string;

  @IsString()
  @IsNotEmpty()
  password: string;

  realm: UserRole;
}

import React, { createContext, PureComponent, useContext } from 'react';
import { Cookies, loadGoogleMapScript } from '../../shared/utility';
import { UserInterface } from '../../shared/interfaces';
import { axiosInstance } from '../../api/axiosInstance';
import { AxiosError } from 'axios';
import { API } from '../../api';

interface State {
  user: UserInterface;
  isInitialized: boolean;
  isAuthenticated: boolean;
}

interface Context extends State {
  updateUser(user: UserInterface): void;

  getLatestUser(): void;
}

export const AuthContext = createContext<Context>(null);

export const useAuthContext = () => useContext(AuthContext);

export class AuthContextProvider extends PureComponent<any, State> {
  state: State = {
    user: null,
    isInitialized: false,
    isAuthenticated: false
  };

  componentDidMount() {
    this.responseInterceptor();
    setTimeout(() => {
      loadGoogleMapScript().then(() => {
        this.setState({
          isInitialized: true
        });
      });
    }, 2000);
    const user = Cookies.get('current-user');
    if (user) {
      this.setState({ user, isAuthenticated: true });
      this.getLatestUser();
    }
  }

  getLatestUser(): void {
    API.Auth.me().then((response) => {
      this.updateUser(response.data);
    });
  }

  responseInterceptor() {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (
          error.response.status === 401 ||
          error.response.status === 400 ||
          error.response.status === 404
        ) {
          this.updateUser(null);
        }

        return Promise.reject(error);
      }
    );
  }

  updateUser(user: UserInterface): void {
    if (user) {
      Cookies.set('current-user', user);
      if (user.token) {
        Cookies.set('access-token', user.token);
      }
      this.setState({ user, isAuthenticated: true });
    } else {
      Cookies.remove('current-user');
      Cookies.remove('access-token');
      this.setState({ user, isAuthenticated: false });
    }
  }

  render() {
    const context: Context = {
      ...this.state,
      updateUser: this.updateUser.bind(this),
      getLatestUser: this.getLatestUser.bind(this)
    };
    return (
      <AuthContext.Provider value={context}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

import { useCallback, useState } from 'react';

export function useSearch(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);
  const [search, setSearch] = useState(defaultValue);
  const [timer, setTimer] = useState<NodeJS.Timeout>(null);

  const handleChange = useCallback(
    (text: string) => {
      setValue(text);
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          setSearch(text);
          setTimer(null);
        }, 300)
      );
    },
    [timer, setTimer, setValue, setSearch]
  );

  return { searchText: search, onChangeText: handleChange, value };
}

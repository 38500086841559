import React from 'react';
import { AxiosPromise } from 'axios';
import { axiosInstance } from './axiosInstance';
import { ProductsValidator } from '../validators';
import { ProductsInterface, ProductType } from '../shared/interfaces';

export class Products extends React.Component {
  static create(data: ProductsValidator): AxiosPromise<ProductsInterface> {
    return axiosInstance({
      url: '/product/create',
      method: 'post',
      data
    });
  }

  static list(type: ProductType[]): AxiosPromise<ProductsInterface[]> {
    return axiosInstance({
      url: '/product/list',
      method: 'post',
      data: { type }
    });
  }

  static getById(id: string): AxiosPromise<ProductsInterface> {
    return axiosInstance({
      url: `/product/${id}`,
      method: 'get'
    });
  }

  static updateById(
    id: string,
    data: ProductsValidator
  ): AxiosPromise<ProductsInterface> {
    return axiosInstance({
      url: `/product/${id}`,
      method: 'patch',
      data
    });
  }

  static deleteById(id: string): AxiosPromise<ProductsInterface> {
    return axiosInstance({
      url: `/product/${id}`,
      method: 'delete'
    });
  }
}

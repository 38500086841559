import React, { useEffect, useState } from 'react';
import { UpdateProductDialog } from '../UpdateProductDialog';
import { useDispatch, useSelector } from '../../../../../../store';
import {
  deleteProductThunk,
  productsListThunk
} from '../../../../../../thunks';
import {
  ProductsInterface,
  ProductWarranty
} from '../../../../../../shared/interfaces';

export function DesktopProducts(): JSX.Element {
  const [showUpdate, setShowUpdate] = useState<ProductsInterface>(null);

  const handleShowUpdate = (product: ProductsInterface) => () => {
    setShowUpdate(product);
  };
  const handleCloseUpdate = () => {
    setShowUpdate(null);
  };

  const dispatch = useDispatch();
  const products = useSelector((state) => state.productsList);

  useEffect(() => {
    dispatch(productsListThunk());
  }, [dispatch]);

  const handleDeleteProduct = (id: string) => async () => {
    const value = window.confirm(
      'Are you sure you want to delete this Product!'
    );

    if (value) {
      await dispatch(deleteProductThunk(id));
      dispatch(productsListThunk());
    }
  };
  return (
    <>
      <div className="products">
        {products.installable.map((product) => (
          <div className="product" key={product.id}>
            <div className="label" data-type={product.type}>
              {product.type}
            </div>
            <img src={product.images[0]} alt="" />

            <div className="details">
              <h1>{product.title}</h1>
              {!product.warrantyPeriod && (
                <h1 className="warranty-label">Non Warranty</h1>
              )}
              {product.warrantyPeriod === ProductWarranty['six-month'] && (
                <h1 className="warranty-label">Six Month</h1>
              )}
              {product.warrantyPeriod === ProductWarranty['one-year'] && (
                <h1 className="warranty-label">One Year</h1>
              )}

              <div className="product-code">{product.id}</div>
              {!!product.description && <p>{product.description}</p>}
              <div className="btns d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <span className="original-price">
                    <span>{product.price}</span>
                  </span>
                  <span className="current-price">
                    <span>{product.discountedPrice}</span>
                  </span>
                </div>
                <div className="product-btns">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleShowUpdate(product)}>
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={handleDeleteProduct(product.id)}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        {products.replaceable.map((product) => (
          <div className="product" key={product.id}>
            <div className="label">{product.type}</div>
            <img src={product.images[0]} alt="" />
            <div className="details">
              <h1>{product.title}</h1>
              <div className="btns d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <span className="original-price">
                    <span>{product.price}</span>
                  </span>
                  <span className="current-price">
                    <span>{product.discountedPrice}</span>
                  </span>
                </div>
                <div className="product-btns">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleShowUpdate(product)}>
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={handleDeleteProduct(product.id)}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Update Product Dialog */}
      {!!showUpdate && (
        <UpdateProductDialog
          open={!!showUpdate}
          product={showUpdate}
          onClose={handleCloseUpdate}
        />
      )}
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { ordersSummaryThunk, statisticsThunk } from '../../thunks';
import { StatisticsResponseInterface } from '../../interfaces';
import { OrderPopulatedInterface } from '../../shared/interfaces';

interface StatisticsState {
  users: StatisticsResponseInterface;
  orders: OrderPopulatedInterface;
  loading: boolean;
}

const initialState: StatisticsState = {
  users: null,
  orders: null,
  loading: false
};
export const statisticsSlice = createSlice({
  name: 'statistics-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Statistics
    builder
      .addCase(statisticsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(statisticsThunk.fulfilled, (state, { payload }) => {
        state.users = payload;
        state.loading = false;
      })
      .addCase(statisticsThunk.rejected, (state) => {
        state.loading = false;
      });
    //Orders Summary
    builder
      .addCase(ordersSummaryThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(ordersSummaryThunk.fulfilled, (state, { payload }) => {
        state.orders = payload;
        state.loading = false;
      })
      .addCase(ordersSummaryThunk.rejected, (state) => {
        state.loading = false;
      });
  }
});

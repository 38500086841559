import { UserInterface } from './user.interface';

export interface CartInterface {
  product: string;
  quantity: number;
  price: number;
  total: number;
}

export enum OrderStatus {
  Pending = 'pending',
  Completed = 'completed'
}

export interface OrderInterface {
  id: string;
  cart: CartInterface[];
  buyer: string;
  expert: string;
  total: number;
  paid: number;
  images: string[];
  pending: number;
  previousPending: number;
  status: OrderStatus;
}

export interface OrderPopulatedInterface
  extends Omit<OrderInterface, 'buyer' | 'expert'> {
  buyer: UserInterface;
  expert: UserInterface;
}

export interface PaymentInterface {
  id: string;
  amount: number;
  clear: boolean;
}

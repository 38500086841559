import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';
import { ProductsInterface, ProductType } from '../../shared/interfaces';

export const productsInstallableThunk = createAsyncThunk<ProductsInterface[]>(
  'products-installable-thunk',
  async (data: void, thunkApi) => {
    try {
      const response = await API.Products.list([ProductType.Installable]);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

export const productsReplaceableThunk = createAsyncThunk<ProductsInterface[]>(
  'products-replaceable-thunk',
  async (data: void, thunkApi) => {
    try {
      const response = await API.Products.list([ProductType.Replaceable]);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

export const productsListThunk = createAsyncThunk<ProductsInterface[]>(
  'products-list-thunk',
  async (data: void, thunkApi) => {
    try {
      const response = await API.Products.list([
        ProductType.Installable,
        ProductType.Replaceable
      ]);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

export const deleteProductThunk = createAsyncThunk<ProductsInterface, string>(
  'delete-product-thunk',
  async (id, thunkApi) => {
    try {
      const response = await API.Products.deleteById(id);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

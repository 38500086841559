import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';
import {
  OrderFilterInterface,
  OrderPopulatedInterface,
  PaymentInterface,
  ProductType
} from '../../shared/interfaces';

export const listOrderThunk = createAsyncThunk<
  OrderPopulatedInterface[],
  OrderFilterInterface
>('list-work-thunk', async (data, thunkApi) => {
  try {
    const response = await API.Orders.filter(data);
    return response.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response);
  }
});
export const makePaymentThunk = createAsyncThunk<
  OrderPopulatedInterface,
  PaymentInterface
>('make-payment-thunk', async (data, thunkApi) => {
  try {
    const response = await API.Orders.payment(data);
    return response.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response);
  }
});

export const ordersSummaryThunk = createAsyncThunk<
  OrderPopulatedInterface,
  void
>('orders-summary-thunk', async (data, thunkApi) => {
  try {
    const response = await API.Orders.summary();
    return response.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response);
  }
});

import { useEffect, useState } from 'react';

export function useMediaQuery(mediaQuery: string): boolean {
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    const list = window.matchMedia(mediaQuery);
    setIsMatch(list.matches);
    const listener = (event: MediaQueryListEvent) => {
      console.log(event);
      setIsMatch(event.matches);
    };
    list.addEventListener('change', listener);

    return () => {
      list.removeEventListener('change', listener);
    };
  }, [mediaQuery]);

  return isMatch;
}

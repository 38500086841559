import { combineReducers } from '@reduxjs/toolkit';
import {
  orderSlice,
  productsSlice,
  statisticsSlice,
  usersSlice
} from '../slices';

const rootReducer = combineReducers({
  usersList: usersSlice.reducer,
  productsList: productsSlice.reducer,
  orders: orderSlice.reducer,
  statistics: statisticsSlice.reducer
});

export default rootReducer;

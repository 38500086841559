import React from 'react';
import { AxiosPromise } from 'axios';
import { axiosInstance } from './axiosInstance';
import {
  OrderFilterInterface,
  OrderPopulatedInterface,
  PaymentInterface
} from '../shared/interfaces';

export class Orders extends React.Component {
  static payment(
    data: PaymentInterface
  ): AxiosPromise<OrderPopulatedInterface> {
    return axiosInstance({
      url: '/order/payment',
      method: 'post',
      data
    });
  }

  static filter(
    data: OrderFilterInterface
  ): AxiosPromise<OrderPopulatedInterface[]> {
    return axiosInstance({
      url: '/order/filter',
      method: 'post',
      data
    });
  }

  static summary(): AxiosPromise<OrderPopulatedInterface> {
    return axiosInstance({
      url: '/order/summary',
      method: 'post'
    });
  }
}

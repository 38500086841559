import React from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { LoginFormValidator } from '../../../../../validators';
import { FormikValidator } from '../../../../../shared/utility';
import { FormikConfig } from 'formik/dist/types';
import { Link } from 'react-router-dom';
import checkbox from '../../../../../assets/icons/checkbox-primary-icon.svg';
import { useAuthContext } from '../../../../contexts';
import { API } from '../../../../../api';
import { UserRole } from '../../../../../shared/interfaces';

export function Login(): JSX.Element {
  const authContext = useAuthContext();
  const formik: FormikConfig<LoginFormValidator> = {
    initialValues: {
      email: '',
      password: '',
      realm: UserRole.Admin
    },
    onSubmit(values): void | Promise<any> {
      API.Auth.login({ ...values })
        .then((response) => {
          console.log(response);
          authContext.updateUser(response.data);
        })
        .then((error) => {
          console.error(error);
        });
    },

    validate: FormikValidator.validator(LoginFormValidator)
  };
  return (
    <div className="login d-flex justify-content-center align-items-center">
      <Formik {...formik}>
        {(formikProps) => (
          <Form>
            <h1>Login</h1>
            <div className="mb-2 mt-4">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="Email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
              <ErrorMessage
                name="email"
                render={(error) => <span className="error">{error}</span>}
              />
            </div>
            <div className="mb-1">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                id="password"
                placeholder="Password"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
              <ErrorMessage
                name="password"
                render={(error) => <span className="error">{error}</span>}
              />
            </div>
            <div className="more-opt d-flex justify-content-between align-items-center mt-2">
              <div className="d-flex align-items-center">
                <img src={checkbox} alt="" />
                <p>Remember me</p>
              </div>
              <Link to="/">Forgot Password?</Link>
            </div>
            <div className="mt-3">
              <button className="btn btn-primary" type="submit">
                Login
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

import React from 'react';
import { PaymentList } from './components';

export function PaymentsView(): JSX.Element {
  return (
    <div className="content payments-view">
      <div className="same-lists mt-4">
        <div className="list-header payment-list-header">
          <h1>Payments</h1>
        </div>
        <PaymentList />
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import clsx from 'clsx';
import { Cards, List } from './components';
import { useMediaQuery } from '../../hooks';

export function ChangeFilterList(): JSX.Element {
  const isMatch = useMediaQuery('(max-width: 600px)');

  const [listType, setListType] = useState<'today' | 'tomorrow' | 'week'>(
    'today'
  );
  return (
    <div className="same-lists mt-4" style={{ display: 'none' }}>
      <div className="list-header d-flex justify-content-between align-items-center">
        <h1>Change Filters (23)</h1>
        <div className="list-type">
          <button
            type="button"
            className={clsx('btn btn-primary', {
              active: listType === 'today'
            })}
            onClick={() => setListType('today')}>
            Today
          </button>
          <button
            type="button"
            className={clsx('btn btn-primary', {
              active: listType === 'tomorrow'
            })}
            onClick={() => setListType('tomorrow')}>
            Tomorrow
          </button>
          <button
            type="button"
            className={clsx('btn btn-primary', { active: listType === 'week' })}
            onClick={() => setListType('week')}>
            Week
          </button>
        </div>
      </div>
      {listType === 'today' && <>{isMatch ? <Cards /> : <List />}</>}
      {listType === 'tomorrow' && <>{isMatch ? <Cards /> : <List />}</>}
      {listType === 'week' && <>{isMatch ? <Cards /> : <List />}</>}
    </div>
  );
}

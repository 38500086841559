import React from 'react';
import { DesktopRouter } from './views/desktop/Router';
import { useAuthContext } from './contexts';
import { MainLayout } from './layouts/MainLayout';
import { Splash as DesktopSplash } from './views/desktop';

export function App(): JSX.Element {
  const authContext = useAuthContext();
  return (
    <div className="App">
      {authContext.isInitialized ? (
        <>{authContext.isAuthenticated ? <MainLayout /> : <DesktopRouter />}</>
      ) : (
        <DesktopSplash />
      )}
    </div>
  );
}

import React, { ChangeEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import crossIcon from '../../../../../../assets/icons/cross-icon.svg';
import { useDispatch } from '../../../../../../store';
import { API } from '../../../../../../api';
import { makePayment } from '../../../../../../slices';

interface Props {
  id: string;
  open: boolean;
  onClose: () => void;
}

export function AddPaymentDialog(props: Props): JSX.Element {
  const handleClose = () => props.onClose();
  const [payment, setPayment] = useState<string>('');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPayment(event.currentTarget.value.replace(/[^0-9]/g, ''));
  };
  const dispatch = useDispatch();
  const handlePayment = () => {
    API.Orders.payment({
      id: props.id,
      amount: Number(payment),
      clear: false
    })
      .then(async (response) => {
        await dispatch(makePayment(response.data));
        if (payment) {
          props.onClose();
        }
      })
      .catch((error) => {
        if (!!error.response && error.response.status === 422) {
          if (!!error.response.data && error.response.data.amount) {
            alert(error.response.data.amount);
          }
        }
      });
  };

  return (
    <div>
      <Modal
        centered
        className="modal new-buyer"
        show={props.open}
        onHide={handleClose}>
        <div className="modal-container">
          <span className="close-modal" onClick={handleClose}>
            <img src={crossIcon} alt="" />
          </span>
          <h1>Add Payment</h1>
          <form>
            <div className="mb-1 mt-3 col">
              <label htmlFor="amount" className="form-label">
                Add Payment
              </label>
              <input
                type="number"
                className="form-control"
                id="amount"
                name="amount"
                onChange={handleChange}
                placeholder="Add Payment"
              />
            </div>
            <div className="mt-3">
              <button
                className="btn btn-primary"
                type="button"
                onClick={handlePayment}>
                Add Payment
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

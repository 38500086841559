import React from 'react';
import { AxiosPromise } from 'axios';
import { axiosInstance } from './axiosInstance';
import { LoginFormValidator } from '../validators';
import {
  UserCsvData,
  UserFilterInterface,
  UserInterface
} from '../shared/interfaces';
import {
  StatisticsFilterInterface,
  StatisticsResponseInterface
} from '../interfaces';

export class Auth extends React.Component {
  static login(data: LoginFormValidator): AxiosPromise<UserInterface> {
    return axiosInstance({
      url: '/auth/login',
      method: 'post',
      data
    });
  }

  static create(data: any): AxiosPromise<UserInterface> {
    return axiosInstance({
      url: '/user/create',
      method: 'post',
      data
    });
  }

  static bulkCreate(data: UserCsvData[]): AxiosPromise<UserInterface[]> {
    return axiosInstance({
      url: '/user/create-bulk',
      method: 'post',
      data: {
        data
      }
    });
  }

  static list(filter: UserFilterInterface): AxiosPromise<UserInterface[]> {
    return axiosInstance({
      url: '/user/list',
      method: 'post',
      data: filter
    });
  }

  static me(): AxiosPromise<UserInterface> {
    return axiosInstance({
      url: '/user/me',
      method: 'get'
    });
  }

  static getById(id: string): AxiosPromise<UserInterface> {
    return axiosInstance({
      url: `/user/${id}`,
      method: 'get'
    });
  }

  static updateById(id: string, data: any): AxiosPromise<UserInterface> {
    return axiosInstance({
      url: `/user/${id}`,
      method: 'patch',
      data
    });
  }

  static deleteById(id: string): AxiosPromise<UserInterface> {
    return axiosInstance({
      url: `/user/${id}`,
      method: 'delete'
    });
  }

  static changePassword(
    id: string,
    data: {
      previousPassword: string;
      password: string;
    }
  ): AxiosPromise<UserInterface> {
    return axiosInstance({
      url: '/user/reset-password',
      method: 'delete'
    });
  }

  static changePasswordById(
    id: string,
    password: string
  ): AxiosPromise<UserInterface> {
    return axiosInstance({
      url: `/user/reset-password/${id}`,
      method: 'post',
      data: {
        password
      }
    });
  }

  static statistics(
    data: StatisticsFilterInterface
  ): AxiosPromise<StatisticsResponseInterface> {
    return axiosInstance({
      url: '/user/statistics',
      method: 'post',
      data: data
    });
  }
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import crossIcon from '../../../../../../assets/icons/cross-icon.svg';
import { ChangePasswordValidator } from '../../../../../../validators';
import { FormikValidator } from '../../../../../../shared/utility';
import { useFormik } from 'formik';
import { API } from '../../../../../../api';
import { FormikErrorMessage } from '../../../../../components';

interface Props {
  open: boolean;
  id: string;
  onClose: () => void;
}

export function ChangePasswordDialog(props: Props): JSX.Element {
  const handleClose = () => props.onClose();
  const formik = useFormik<ChangePasswordValidator>({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    onSubmit(values): void | Promise<any> {
      API.Auth.changePasswordById(props.id, values.password)
        .then((response) => {
          console.log(response);
          handleClose();
        })
        .then((error) => {
          console.error(error);
        });
    },

    validate: FormikValidator.validator(ChangePasswordValidator)
  });

  return (
    <div>
      <Modal centered className="modal" show={props.open} onHide={handleClose}>
        <div className="modal-container">
          <span className="close-modal" onClick={handleClose}>
            <img src={crossIcon} alt="" />
          </span>
          <h1>Change Password</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="row mt-3 gx-3">
              <div className="mb-1 col">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="password"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              <div className="mb-1 col">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="confirmPassword"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="mt-2">
              <button className="btn btn-primary" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

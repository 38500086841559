import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import crossIcon from '../../../../../../assets/icons/cross-icon.svg';
import { UpdateExpertValidator } from '../../../../../../validators';
import { FormikValidator } from '../../../../../../shared/utility';
import { useFormik } from 'formik';
import { API } from '../../../../../../api';
import { FormikErrorMessage } from '../../../../../components';
import { useDispatch } from '../../../../../../store';
import { usersThunk } from '../../../../../../thunks';
import { UserRole } from '../../../../../../shared/interfaces';

interface Props {
  open: boolean;
  id: string;
  onClose: () => void;
}

export function UpdateExpertDialog(props: Props): JSX.Element {
  const handleClose = () => props.onClose();
  const dispatch = useDispatch();
  const formik = useFormik<UpdateExpertValidator>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    },
    onSubmit(values): void | Promise<any> {
      API.Auth.updateById(props.id, { ...values })
        .then((response) => {
          console.log(response);
          handleClose();
          dispatch(usersThunk({ role: [UserRole.Expert] }));
        })
        .then((error) => {
          console.error(error);
        });
    },

    validate: FormikValidator.validator(UpdateExpertValidator)
  });

  useEffect(() => {
    if (props.id) {
      API.Auth.getById(props.id).then((response) => {
        const user = response.data;
        formik.setValues({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber
        });
      });
    }
  }, [props.id]);

  return (
    <div>
      <Modal centered className="modal" show={props.open} onHide={handleClose}>
        <div className="modal-container">
          <span className="close-modal" onClick={handleClose}>
            <img src={crossIcon} alt="" />
          </span>
          <h1>Edit Expert</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="row mt-3 gx-3">
              <div className="mb-1 col">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="firstName"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              <div className="mb-1 col">
                <label htmlFor="lastNames" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="lastName"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-1 col">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="email"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              <div className="mb-3 col">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                <FormikErrorMessage
                  formik={formik}
                  name="phoneNumber"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>

            <div className="mt-2">
              <button className="btn btn-primary" type="submit">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

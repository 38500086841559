export enum ProductType {
  Installable = 'installable',
  Replaceable = 'replaceable'
}

export const ProductWarranty = {
  'non-warranty': 0,
  'six-month': 1000 * 60 * 60 * 24 * 30 * 6,
  'one-year': 1000 * 60 * 60 * 24 * 30 * 12
};

export enum ProductCategory {
  Installable = 'installable',
  Filters = 'filters',
  PostCarbon = 'post-carbon',
  Minerals = 'minerals',
  Accessories = 'accessories'
}

export interface ProductsInterface {
  id: string;
  title: string;
  images: string[];
  description: string;
  price: number;
  discountedPrice: number;
  warrantyPeriod: number;
  category: ProductCategory;
  type: ProductType;
  deleted: boolean;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';
import {
  UserCsvData,
  UserFilterInterface,
  UserInterface
} from '../../shared/interfaces';

export const usersThunk = createAsyncThunk(
  'users-thunk',
  async (filter: UserFilterInterface, thunkApi) => {
    try {
      const response = await API.Auth.list(filter);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

export const deleteUserThunk = createAsyncThunk<any, string>(
  'delete-user-thunk',
  async (id, thunkApi) => {
    try {
      const response = await API.Auth.deleteById(id);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

export const bulkCreateUserThunk = createAsyncThunk<
  UserInterface[],
  UserCsvData[]
>('bulk-create-user-thunk', async (data: UserCsvData[], thunkApi) => {
  try {
    const response = await API.Auth.bulkCreate(data);
    return response.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response);
  }
});

import React, { useEffect, useState } from 'react';
// import { PaymentDetailsDialog } from '../PaymentDetailsDialog';
import { ReactComponent as SearchIcon } from '../../../../../../assets/icons/search-icon.svg';
import { useDispatch, useSelector } from '../../../../../../store';
import { listOrderThunk } from '../../../../../../thunks';
import { OrderStatus } from '../../../../../../shared/interfaces';
import moment from 'moment';
import { AddPaymentDialog } from '../AddpaymentDialog';

export function PaymentList(): JSX.Element {
  // const [showDetails, setShowDetails] = useState(false);
  const [addPaymentDialog, setAddPaymentDialog] = useState<string>(null);
  // const handleShowDetails = () => {
  //   setShowDetails(true);
  // };
  // const handleCloseDetails = () => {
  //   setShowDetails(false);
  // };
  const handleShowAddPayment = (id: string) => () => {
    setAddPaymentDialog(id);
  };
  const handleCloseAddPayment = () => {
    setAddPaymentDialog(null);
  };
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(listOrderThunk({ status: OrderStatus.Completed }));
  }, []);
  return (
    <>
      <div className="filters d-flex">
        <div className="combine-input d-flex align-items-center form-control">
          <button className="btn" type="submit">
            <SearchIcon />
          </button>
          <input
            type="text"
            name="text"
            className="form-control"
            id="search"
            placeholder="Keywords"
          />
        </div>
        <button className="btn btn-primary search-btn" type="button">
          Search
        </button>
      </div>
      <div className="table-responsive">
        <table>
          <tr className="list-headings">
            <th>Customer Name</th>
            <th>Address</th>
            <th>Phone Number</th>
            <th>Last Change Date</th>
            <th>Receiver</th>
            <th>Paid</th>
            <th>pending</th>
            <th />
          </tr>
          {orders.data.map((order) => (
            <tr key={order.id}>
              <td>
                {order.buyer.firstName} {order.buyer.lastName}
              </td>
              <td>{order.buyer?.address?.locality}</td>
              <td>{order.buyer.phoneNumber}</td>

              <td>
                {moment(order.buyer.createdAt).format(moment.HTML5_FMT.DATE)}
              </td>
              <td>
                {order.expert.firstName} {order.expert.lastName}
              </td>
              <td>
                <button className="btn btn-nill" type="button">
                  {order.paid || 0}
                </button>
              </td>
              <td>
                <button className="btn btn-danger" type="button">
                  {order.pending}
                </button>
              </td>
              <td>
                {order.pending !== 0 && (
                  <button
                    className="btn btn-primary pointer"
                    type="button"
                    onClick={handleShowAddPayment(order.id)}>
                    Add Payment
                  </button>
                )}
              </td>
            </tr>
          ))}
        </table>
      </div>
      {/* Payment details */}
      {/* <PaymentDetailsDialog open={showDetails} onClose={handleCloseDetails} /> */}
      <AddPaymentDialog
        open={!!addPaymentDialog}
        id={addPaymentDialog}
        onClose={handleCloseAddPayment}
      />
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { bulkCreateUserThunk, deleteUserThunk, usersThunk } from '../../thunks';
import { UserInterface, UserRole } from '../../shared/interfaces';

interface UsersState {
  data: UserInterface[];
  buyers: UserInterface[];
  experts: UserInterface[];
  searchText: string;
  loading: boolean;
  deleting: string;
  created: string;
  error: any;
}

const initialState: UsersState = {
  data: [],
  buyers: [],
  experts: [],
  searchText: null,
  loading: false,
  deleting: null,
  created: null,
  error: null
};
export const usersSlice = createSlice({
  name: 'users-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Users
    builder
      .addCase(usersThunk.pending, (state) => {
        state.loading = !state.data.length;
        state.error = null;
      })
      .addCase(usersThunk.fulfilled, (state, { payload }) => {
        state.data = (payload as UserInterface[]) ?? [];
        state.buyers = ((payload as UserInterface[]) ?? []).filter((item) =>
          item.roles.includes(UserRole.Buyer)
        );
        state.experts = ((payload as UserInterface[]) ?? []).filter((item) =>
          item.roles.includes(UserRole.Expert)
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(usersThunk.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
        state.loading = false;
      });
    // Delete User
    builder.addCase(deleteUserThunk.pending, (state) => {
      state.error = null;
    });
    builder.addCase(deleteUserThunk.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteUserThunk.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
      state.loading = false;
    });
    builder.addCase(bulkCreateUserThunk.fulfilled, (state, action) => {
      state.data = [...state.data, ...action.payload];
      state.experts = [
        ...state.experts,
        ...action.payload.filter((item) => item.roles.includes(UserRole.Expert))
      ];
      state.buyers = [
        ...state.buyers,
        ...action.payload.filter((item) => item.roles.includes(UserRole.Buyer))
      ];
      state.loading = false;
    });
  }
});

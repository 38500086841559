import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import crossIcon from '../../../../../../assets/icons/cross-icon.svg';
import { FormikValidator } from '../../../../../../shared/utility';
import { useFormik } from 'formik';
import { NewBuyerValidator } from '../../../../../../validators';
import { FormikErrorMessage } from '../../../../../components';
import { ProductWarranty, UserRole } from '../../../../../../shared/interfaces';
import { useDispatch, useSelector } from '../../../../../../store';
import {
  productsInstallableThunk,
  productsReplaceableThunk,
  usersThunk
} from '../../../../../../thunks';
import { API } from '../../../../../../api';
import moment from 'moment';

interface Props {
  open: boolean;
  onClose: () => void;
}

export function NewBuyerDialog(props: Props): JSX.Element {
  const handleClose = () => props.onClose();
  const dispatch = useDispatch();
  const newBuyerFormik = useFormik<NewBuyerValidator>({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      address: {
        locality: '',
        location: {
          type: 'Point',
          coordinates: [0, 0]
        }
      },
      role: UserRole.Buyer,
      productPrice: 0,
      payment: 0,
      product: null,
      installationDate: moment().format(moment.HTML5_FMT.DATE),
      nextServiceDate: moment().add(3, 'month').format(moment.HTML5_FMT.DATE),
      specialDiscount: 0,
      payable: 0,
      sn: ''
    },
    onSubmit: (values, formikHelpers) => {
      API.Auth.create({
        ...values,
        installationDate: new Date(values.installationDate).toISOString()
      })
        .then((response) => {
          console.log(response);
          formikHelpers.resetForm();
          dispatch(usersThunk({ role: [UserRole.Buyer] }));
          props.onClose();
        })
        .catch((error) => {
          console.log('Failed =>', error.response);
        });
    },

    validate: FormikValidator.validator(NewBuyerValidator)
  });
  const products = useSelector((state) => state.productsList);
  useEffect(() => {
    dispatch(productsInstallableThunk);
    dispatch(productsReplaceableThunk);
  }, [dispatch]);

  useEffect(() => {
    if (newBuyerFormik.values.productPrice) {
      newBuyerFormik.setFieldValue(
        'payable',
        newBuyerFormik.values.specialDiscount
          ? newBuyerFormik.values.productPrice -
              newBuyerFormik.values.specialDiscount
          : newBuyerFormik.values.productPrice
      );
    }
  }, [
    newBuyerFormik.values.productPrice,
    newBuyerFormik.values.specialDiscount,
    newBuyerFormik.setFieldValue
  ]);

  useEffect(() => {
    const product = products.installable.find(
      (item) => item.id === newBuyerFormik.values.product
    );
    if (product) {
      newBuyerFormik.setFieldValue('productPrice', product.discountedPrice);
    }
  }, [
    newBuyerFormik.values.product,
    newBuyerFormik.setFieldValue,
    products.installable
  ]);

  return (
    <div>
      <Modal centered className="modal" show={props.open} onHide={handleClose}>
        <div className="modal-container">
          <span className="close-modal" onClick={handleClose}>
            <img src={crossIcon} alt="" />
          </span>
          <h1>Add New Buyer</h1>
          <form onSubmit={newBuyerFormik.handleSubmit}>
            <div className="row mt-3 gx-3">
              <div className="mb-1 col">
                <label htmlFor="firstName" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="firstName"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              <div className="mb-1 col">
                <label htmlFor="lastName" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="lastName"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-1 col">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="phoneNumber"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>

              <div className="mt-3">
                <label htmlFor="address.locality" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="address.locality"
                  id="address.locality"
                  placeholder="Address"
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="address.locality"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-3 col">
                <label htmlFor="product" className="form-label">
                  Select Product
                </label>
                <select
                  id="product"
                  name="product"
                  className="form-select form-control"
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}>
                  <option value={null} disabled selected>
                    product
                  </option>
                  {products.installable.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.title}{' '}
                      {!product.warrantyPeriod && '(Non Warranty)'}
                      {product.warrantyPeriod ===
                        ProductWarranty['six-month'] && '(Six Month Warranty)'}
                      {product.warrantyPeriod === ProductWarranty['one-year'] &&
                        '(One Year Warranty)'}
                    </option>
                  ))}
                </select>
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="product"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sn" className="form-label">
                  Product Serial Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="sn"
                  id="sn"
                  placeholder="xxxx-xxxxxxxx-xxx"
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="sn"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="row mt-3 gx-3">
              <div className="mb-3 col">
                <label htmlFor="installationDate" className="form-label">
                  Installation Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="installationDate"
                  id="installationDate"
                  value={newBuyerFormik.values.installationDate}
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="installationDate"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
              <div className="mb-3 col">
                <label htmlFor="nextServiceDate" className="form-label">
                  Next Service Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="nextServiceDate"
                  id="nextServiceDate"
                  value={newBuyerFormik.values.nextServiceDate}
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="nextServiceDate"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>

            <div className="row mt-1 gx-3">
              <div className="mb-3 col">
                <label htmlFor="payment" className="form-label">
                  Add Payment{' '}
                  {!!newBuyerFormik.values.payable && (
                    <>
                      (Payable Amount:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {newBuyerFormik.values.payable}
                      </span>
                      )
                    </>
                  )}
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="payment"
                  id="payment"
                  placeholder="Add Payment"
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="payment"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="row mt-1 gx-3">
              <div className="mb-3 col">
                <label htmlFor="specialDiscount" className="form-label">
                  Special Discount
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="specialDiscount"
                  id="specialDiscount"
                  placeholder="Special Discount"
                  onChange={newBuyerFormik.handleChange}
                  onBlur={newBuyerFormik.handleBlur}
                />
                <FormikErrorMessage
                  formik={newBuyerFormik}
                  name="specialDiscount"
                  render={(error) => <span className="error">{error}</span>}
                />
              </div>
            </div>
            <div className="mt-2">
              <button className="btn btn-primary" type="submit">
                Add Buyer
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

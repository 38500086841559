import React, { useState } from 'react';
import { DesktopProducts, NewProductDialog } from './components';
import { useMediaQuery } from '../../../hooks';

export function Products(): JSX.Element {
  const isMatch = useMediaQuery('(max-width: 600px)');

  const [showNew, setShowNew] = useState(false);

  const handleShowNew = () => {
    setShowNew(true);
  };
  const handleCloseNew = () => {
    setShowNew(false);
  };

  return (
    <div className="content">
      <div className="products-section">
        <div className="product-header d-flex justify-content-between align-items-center">
          <h1>Products</h1>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleShowNew}>
            Add Product
          </button>
        </div>
        <DesktopProducts />
      </div>
      {/* Add Product Dialog */}
      {showNew && <NewProductDialog open={showNew} onClose={handleCloseNew} />}
    </div>
  );
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home-icon.svg';
import { ReactComponent as BuyersIcon } from '../../../assets/icons/buyers-icon.svg';
import { ReactComponent as ExpertsIcon } from '../../../assets/icons/experts-icon.svg';
import { ReactComponent as PaymentIcon } from '../../../assets/icons/payment-icon.svg';
import { ReactComponent as ProductsIcon } from '../../../assets/icons/products-icon.svg';
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat-icon.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-icon.svg';
import logo from '../../../assets/images/sm-logo.svg';
import logoutIcon from '../../../assets/icons/logout-icon.svg';
import { useAuthContext } from '../../contexts';

export function Navbar(): JSX.Element {
  const authContext = useAuthContext();

  const handleLogout = () => {
    authContext.updateUser(null);
  };
  return (
    <div className="nav-container">
      <nav className="side-nav">
        <div className="logo text-center mt-3">
          <img src={logo} alt="" />
        </div>
        <ul className="nav-links d-flex flex-column align-items-center justify-content-center">
          <NavLink to="/home" className="aqua-tooltip" data-tooltip="Home">
            <HomeIcon />
          </NavLink>
          <NavLink to="/buyers" className="aqua-tooltip" data-tooltip="Buyer">
            <BuyersIcon />
          </NavLink>
          <NavLink to="/experts" className="aqua-tooltip" data-tooltip="Expert">
            <ExpertsIcon />
          </NavLink>
          <NavLink
            to="/payments"
            className="aqua-tooltip"
            data-tooltip="Payment">
            <PaymentIcon />
          </NavLink>
          <NavLink
            to="/products"
            className="aqua-tooltip"
            data-tooltip="Products">
            <ProductsIcon />
          </NavLink>
          <NavLink
            to="/chat"
            className="aqua-tooltip"
            style={{ display: 'none' }}
            data-tooltip="Chat">
            <ChatIcon />
          </NavLink>
          <NavLink
            to="/settings"
            className="aqua-tooltip"
            data-tooltip="Profile">
            <UserIcon />
          </NavLink>
        </ul>
        <div
          className="logout d-flex flex-column align-items-center aqua-tooltip"
          data-tooltip="Logout">
          <button onClick={handleLogout} type="button" className="btn">
            <img src={logoutIcon} alt="" />
          </button>
        </div>
      </nav>
    </div>
  );
}

import React from 'react';
import csvIcon from '../../../../../../assets/icons/csv-icon.svg';
import plusIcon from '../../../../../../assets/icons/plus-icon.svg';
import checkBox from '../../../../../../assets/icons/checkbox.svg';

export function ChatLogs(): JSX.Element {
  return (
    <div className="chat-logs">
      <div className="heading">
        <h1>Message</h1>
        <button className="btn btn-primary-light" type="button">Send Message to All</button>
      </div>
      <div className="csv-header d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img src={csvIcon} alt="" />
          <h1>CSV Files</h1>
        </div>
        <button className="btn btn-primary-light" type="button">
          <img src={plusIcon} alt="" />
        </button>
      </div>
      <div className="files">
        <h1>Select All</h1>
        <div className="file d-flex align-items-center">
          <img src={checkBox} alt="" />
          <h1>CSV File One</h1>
        </div>
        <div className="file d-flex align-items-center">
          <img src={checkBox} alt="" />
          <h1>CSV File Two</h1>
        </div>
        <div className="file d-flex align-items-center">
          <img src={checkBox} alt="" />
          <h1>CSV File Three</h1>
        </div>
        <div className="file d-flex align-items-center">
          <img src={checkBox} alt="" />
          <h1>CSV File Four</h1>
        </div>
      </div>
    </div>
  );
}

export function loadScript(options: {
  id: string;
  src: string;
  async: boolean;
}): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const script = document.createElement('script');
    script.id = options.id;
    script.src = options.src;
    script.async = options.async;
    document.body.appendChild(script);
    script.onload = (event) => {
      resolve(event);
    };
    script.onerror = (event) => {
      reject(event);
    };
  });
}
